import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Company,
  createNewSiteTag,
  renameCompanySiteTag
} from '../../data/companyData'
import CMCClient from '../../data/cmcClient'
import { LOGOUT } from '../users/actions'
import { AuthenticatedUser, buildAuthenticatedUser } from '../../common/authenticatedUser'

export interface CompanyList {
  [id: string]: Company
}

export const getCompanyList = createAsyncThunk(
  'company/getCompanyList',
  async (credentials: {
    userId: string;
    sessionId: string;
    dataCenter: string;
  }): Promise<CompanyList> => {
    const { userId, sessionId, dataCenter } = credentials
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    const response = await CMCClient.getCompanies(authUser)
    return response.reduce<CompanyList>((acc, data) => {
      acc[data.id] = {
        ...data,
        label: `${data.name} - (${data.id})`
      }
      return acc
    }, {})
  }
)

export const setSelectedCompany = createAsyncThunk(
  'company/setSelectedCompany',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    company: Company
  }): Promise<Company> => {
    const {
      userId, sessionId, dataCenter, company
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    return {
      ...company,
      allTags: await CMCClient.getSelectedCompanyTags(authUser, company.id)
    }
  }
)

/**
 * Creates a new tag and updates the backend data for the provided company
 */
export const createNewTag = createAsyncThunk(
  'company/createNewTag',
  async (args: {
    authUser: AuthenticatedUser,
    companyId: string,
    tagLabel: string
  }) => ({
    companyId: args.companyId,
    tag: await createNewSiteTag(
      args.authUser,
      args.tagLabel
    )
  })
)

export const renameTag = createAsyncThunk(
  'company/renameTag',
  async (args: {
    authUser: AuthenticatedUser,
    companyId: string,
    tagId: string,
    newTagLabel: string
  }) => {
    const response = await renameCompanySiteTag(
      args.authUser,
      args.newTagLabel,
      args.tagId
    )
    if (response === false) {
      // TODO Response indicates that it didn't work
    }
    return {
      companyId: args.companyId,
      tagId: args.tagId,
      newTagLabel: args.newTagLabel
    }
  }
)

export interface CompanyState {
  /** The currently selected company */
  selectedCompany: Company | null;
  /** The list of all companies */
  companyList: CompanyList;
  /** The state of the get companies request */
  companiesRequestState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  renameTagRequestState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState: CompanyState = {
  selectedCompany: null,
  companyList: {},
  companiesRequestState: 'idle',
  renameTagRequestState: 'idle'
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyRequestState: (
      state,
      action: PayloadAction<'idle' | 'pending' | 'fulfilled' | 'rejected'>
    ) => {
      state.companiesRequestState = action.payload
    },
    setRenameTagRequestState: (
      state,
      action: PayloadAction<'idle' | 'pending' | 'fulfilled' | 'rejected'>
    ) => {
      state.renameTagRequestState = action.payload
    }
  },

  extraReducers: (builder) => {
    builder.addCase(LOGOUT, (state) => {
      state.companiesRequestState = 'idle'
      state.companyList = {}
      state.selectedCompany = null
    })

    builder.addCase(getCompanyList.pending, (state) => {
      state.companiesRequestState = 'pending'
    })

    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.companiesRequestState = 'fulfilled'
      state.companyList = action.payload
    })

    builder.addCase(getCompanyList.rejected, (state) => {
      state.companiesRequestState = 'rejected'
    })

    builder.addCase(setSelectedCompany.pending, (state) => {
      state.companiesRequestState = 'pending'
    })

    builder.addCase(setSelectedCompany.fulfilled, (state, action) => {
      state.companiesRequestState = 'fulfilled'
      state.selectedCompany = action.payload
      state.companyList[action.payload.id] = action.payload
    })

    builder.addCase(setSelectedCompany.rejected, (state) => {
      state.companiesRequestState = 'rejected'
    })

    builder.addCase(createNewTag.rejected, () => {
      // TODO
    })

    builder.addCase(createNewTag.pending, () => {
      // TODO
    })

    builder.addCase(createNewTag.fulfilled, (state, action) => {
      const { companyId, tag } = action.payload

      state.selectedCompany?.allTags.push(tag)

      if (state.companyList[companyId] == null) return
      state.companyList[companyId].allTags.push(tag)
    })

    builder.addCase(renameTag.rejected, (state) => {
      state.renameTagRequestState = 'rejected'
    })

    builder.addCase(renameTag.pending, (state) => {
      state.renameTagRequestState = 'pending'
    })

    builder.addCase(renameTag.fulfilled, (state, action) => {
      const { companyId, tagId, newTagLabel } = action.payload

      // Update currently selected company
      if (
        state.selectedCompany
        && state.selectedCompany.id === companyId
      ) {
        state.selectedCompany.allTags.map((tag) => {
          if (tag.id === tagId) {
            tag.label = newTagLabel
          }
          return tag
        })
      }

      // Update the stored company list
      if (state.companyList[companyId] == null) return
      state.companyList[companyId].allTags.forEach((tag) => {
        if (tag.id === tagId) {
          tag.label = newTagLabel
        }
      })

      state.renameTagRequestState = 'fulfilled'
    })
  }
})

export default companySlice.reducer
export const {
  setCompanyRequestState,
  setRenameTagRequestState
} = companySlice.actions
