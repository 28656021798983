import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Checkbox,
  FormControlLabel,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { loginUser, logoutThunk } from '../../../../redux/users/actions'
import config from '../../../../appConfig'
import AlohaLogo from '../../../../logos/AlohaLogo304x83.png'
import VoyixLog from '../../../../logos/VoyixLogo.png'

function mapStateToProps(state: RootState) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchLoginUser: (...args: Parameters<typeof loginUser>) => dispatch(loginUser(...args)),
    dispatchLogout: (...args: Parameters<typeof logoutThunk>) => dispatch(logoutThunk(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type LoginProps = StateProps & DispatchProps;

/**
 * The provided options that you can select from for a
 * data center.
 */
export const dataCenterOptions = [
  'NUA01-SEC',
  'WEA01-SEC',
  'SSA01-SEC',
  'ECA02-SEC',
  'SUA01-PREP',
  'NUA01-PREP'
]

export function Login({ user, dispatchLoginUser, dispatchLogout }: LoginProps) {
  const navigate = useNavigate()
  const [login, setLogin] = useState({
    username: '',
    password: '',
    securityCode: '', // same as Two Factor Token
    dataCenter: '',
    rememberMe: false
  })
  const [loginBtnDisabled, setLoginBtnDisabled] = useState(true)

  // Enables Log In button if input fields are filled in
  useEffect(() => {
    const {
      username, password, securityCode, dataCenter
    } = login
    const inputsFilledIn = Boolean(username && password && securityCode && dataCenter) === false
    setLoginBtnDisabled(inputsFilledIn)
  }, [login])

  // On load, reset login
  useEffect(() => {
    if (user.sessionId && user.dataCenter && user.userId) {
      dispatchLogout(user.sessionId, user.dataCenter, user.userId)
    }
  }, [])

  const handleKeypress = (e: React.KeyboardEvent) => {
    const target: HTMLInputElement = e.target as HTMLInputElement
    if (target.id === 'rememberMe' && e.key === 'Enter') {
      setLogin({ ...login, rememberMe: !login.rememberMe })
    }
    if (
      loginBtnDisabled === false
      && e.key === 'Enter'
      && target.id !== 'rememberMe'
    ) {
      dispatchLoginUser(
        login.username,
        login.password,
        login.securityCode,
        login.dataCenter,
        navigate
      )
    }
  }

  return (
    <Stack
      data-testid="input"
      onKeyPress={handleKeypress}
      spacing={4}
      sx={{
        bgcolor: '#FFFFFF',
        maxWidth: '90%',
        width: '470px',
        alignItems: 'flex-start',
        paddingTop: 2,
        paddingX: 7,
        borderRadius: '8px',
        boxShadow: '0px 0px 0px 1px #E0E0E0'
      }}
    >
      <Stack
        sx={{
          marginX: 1.5,
          marginY: 2,
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <img
          data-testid="Aloha_logo"
          src={AlohaLogo}
          alt="AlohaUpgrades"
        />

        <Typography
          sx={{
            color: '#5F259F',
            fontWeight: 'bold',
            marginTop: 2,
            marginBottom: 2,
            fontSize: 25
          }}
        >
          Solution Upgrades
        </Typography>
      </Stack>
      <Typography
        variant="subtitle2"
        style={{
          margin: 0,
          marginBottom: '5%',
          fontWeight: 500,
          fontSize: '20px'
        }}
      >
        Login
      </Typography>
      <TextField
        variant="standard"
        fullWidth
        select // makes this a drop down (TextField is just a FormControl wrapper)
        id="dataCenter"
        label="Data Center"
        value={login.dataCenter}
        style={{ margin: 0 }}
        onChange={(e) => setLogin({ ...login, dataCenter: e.target.value })}
        inputProps={{
          'data-testid': 'dataCenter'
        }}
      >
        {dataCenterOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="standard"
        fullWidth
        id="username"
        label="Username"
        value={login.username}
        onChange={(e) => setLogin({ ...login, username: e.target.value })}
        inputProps={{
          'data-testid': 'username'
        }}
      />
      <TextField
        variant="standard"
        fullWidth
        type="password"
        id="password"
        label="Password"
        value={login.password}
        onChange={(e) => setLogin({ ...login, password: e.target.value })}
        inputProps={{
          'data-testid': 'password'
        }}
      />
      <TextField
        variant="standard"
        fullWidth
        id="securityCode"
        label="Security Code"
        value={login.securityCode}
        onChange={(e) => {
          // only update value if the inputs are numbers
          if (/^\d*$/g.test(e.target.value)) {
            setLogin({ ...login, securityCode: e.target.value })
          }
        }}
        inputProps={{
          'data-testid': 'securityCode'
        }}
      />

      <FormControlLabel
        control={(
          <Checkbox
            onKeyPress={handleKeypress}
            id="rememberMe"
            checked={login.rememberMe}
            onChange={(e) => setLogin({ ...login, rememberMe: e.target.checked })}
          />
        )}
        label="Remember Me"
      />
      <LoadingButton
        variant="contained"
        disabled={loginBtnDisabled}
        loading={user.loginRequestState === 'pending'}
        endIcon={<ArrowForwardSharpIcon />}
        fullWidth
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          dispatchLoginUser(
            login.username,
            login.password,
            login.securityCode,
            login.dataCenter,
            navigate
          )
        }}
      >
        Log In
      </LoadingButton>
      <Typography variant="body2">
        Use your existing Command Center credentials for the selected data
        center. TEST
      </Typography>
      <Link href="/" variant="body2" sx={{ textDecoration: 'none' }}>
        Forgot password?
      </Link>
      <Stack
        sx={{
          paddingY: 1,
          alignSelf: 'center'
        }}
      >
        <img
          src={VoyixLog}
          alt="NCR Voyix"
          width={120}
        />
        <Typography
          sx={{ alignSelf: 'center', color: '#B9B9B9' }}
          variant="body2"
          data-testid="app-version"
        >
          v.
          {config.appVersion}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
